import { json } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'

import { type Album } from '~/types.ts'
import { cms } from '~/services/cms.server.ts'
import { Button } from '~/components/Button.tsx'
import { About } from '~/components/sections/About.tsx'
import { Contact } from '~/components/sections/Contact.tsx'
import { Hero } from '~/components/sections/Hero.tsx'
import { Portfolio } from '~/components/sections/Portfolio.tsx'
import { Typography } from '~/components/Typography.tsx'

const HeroImgs = {
	webp: '/images/hero.webp',
	png: '/images/hero.png',
	webpSm: '/images/hero-sm.webp',
	pngSm: '/images/hero-sm.png',
}

export async function loader() {
	const {
		portfolioCollection: { items: albums },
	} = await cms<{
		portfolioCollection: {
			items: Album[]
		}
	}>(`
  {
    portfolioCollection(limit: 20) {
      items {
        title,
        slug,
        heroImage {
          url
        }
        videosCollection {
          total
        }
        photosCollection {
          total
        }
      }
    }
  }`)

	return json({ albums })
}

export default function IndexRoute() {
	const { albums } = useLoaderData<typeof loader>()
	return (
		<>
			<Hero
				content={
					<div className="flex h-full flex-col justify-around">
						<div>
							<Typography as="h1" color="key">
								Krzysztof Dąbrowski <br /> Kamerzysta, Fotograf Weselny <br />
								Rymanów i Podkarpacie
							</Typography>
							<Typography
								color="key"
								className="mt-2 font-serif text-heading3 font-medium italic"
							>
								Zatrzymaj Chwilę, Wprowadź Magię. <br /> Fotografia Weselna na
								Najwyższym Poziomie!
							</Typography>
						</div>
						<Link to="/#portfolio">
							<Button size="large" color="key">
								Portfolio
							</Button>
						</Link>
					</div>
				}
				img={HeroImgs}
			/>
			<About />
			<Portfolio albums={albums} />
			<Contact />
		</>
	)
}
