import { motion, useReducedMotion } from 'framer-motion'

import { ContactForm } from '~/components/ContactForm.tsx'
import { Map } from '~/components/Map.tsx'
import { Typography } from '~/components/Typography.tsx'

export function Contact() {
	const reducedMotion = useReducedMotion()
	return (
		<section id="contact" className="bg-secondary py-24">
			<div className="relative mx-auto max-w-screen-xl overflow-x-hidden">
				<motion.div
					initial={{ opacity: 0.75, y: 50 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					transition={{
						duration: reducedMotion ? 0 : 0.5,
					}}
				>
					<Typography as="h2" color="light" className="absolute left-1/2">
						Kontakt
					</Typography>
				</motion.div>

				<div className="flex flex-col py-24 lg:flex-row">
					<div className="flex w-full flex-col items-center gap-12 lg:w-1/2">
						<motion.div
							initial={{ opacity: 0.75, scale: 0.75 }}
							whileInView={{ opacity: 1, scale: 1 }}
							viewport={{ once: true }}
							transition={{
								duration: reducedMotion ? 0 : 0.5,
							}}
							className="outline-box aspect-video w-3/4 bg-black text-key shadow-xl shadow-black"
						>
							<Map />
						</motion.div>
						<motion.div
							initial={{ opacity: 0.75, scale: 0.75 }}
							whileInView={{ opacity: 1, scale: 1 }}
							viewport={{ once: true }}
							transition={{
								duration: reducedMotion ? 0 : 0.5,
							}}
							className="flex flex-col items-center gap-2"
						>
							<Typography color="light">Krzysztof Dąbrowski</Typography>
							<Typography color="light">videokrzysztof@interia.pl</Typography>
							<Typography color="light">607 652 877</Typography>
							<Typography color="light">Rymanów 38-480</Typography>
							<Typography color="light">Łazy 70</Typography>
						</motion.div>
					</div>
					<motion.div
						initial={{ opacity: 0.75, x: 200 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{
							duration: reducedMotion ? 0 : 0.5,
						}}
						className="w-full py-6 lg:w-1/2"
					>
						<ContactForm />
					</motion.div>
				</div>
			</div>
		</section>
	)
}
