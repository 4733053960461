import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

const mapStyles: google.maps.MapTypeStyle[] = [
	{
		featureType: 'road',
		elementType: 'geometry',
		stylers: [
			{
				lightness: 100,
			},
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				visibility: 'on',
			},
			{
				color: '#C6E2FF',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#C5E3BF',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry.fill',
		stylers: [
			{
				color: '#D1D1B8',
			},
		],
	},
]

const center = {
	lat: 49.5828157,
	lng: 21.9155658,
}

export function Map() {
	let { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: ENV.GOOGLE_API_KEY,
	})

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={{
				width: '100%',
				height: '100%',
			}}
			center={center}
			zoom={12.7}
			options={{
				disableDoubleClickZoom: true,
				draggable: true,
				controlSize: 24,
				styles: mapStyles,
			}}
		>
			<Marker position={center} />
		</GoogleMap>
	) : (
		<></>
	)
}
