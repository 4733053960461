import * as React from 'react'

import { cn } from '~/utils/index.ts'

type Props<T extends React.ElementType> = {
	as?: T
	label: string
	color?: 'light' | 'dark'
} & React.ComponentPropsWithoutRef<T>

export function InputComponent<T extends React.ElementType = 'input'>(
	{ as, label, color = 'dark', className, ...props }: Props<T>,
	ref: React.ForwardedRef<HTMLInputElement>,
) {
	const Component = as || 'input'
	return (
		<label
			className={cn(
				'flex w-full flex-col gap-1 font-sans text-base font-light',
				{
					'text-black': color === 'dark',
					'text-white': color === 'light',
				},
				className,
			)}
		>
			{label}
			<Component
				ref={ref}
				className="w-full rounded border border-current bg-transparent p-3 font-light outline-offset-4 focus:outline-2"
				{...props}
			/>
		</label>
	)
}

export const Input = React.forwardRef(InputComponent)

Input.displayName = 'Input'
