import { Link } from '@remix-run/react'
import { motion, useReducedMotion } from 'framer-motion'

import { type Album } from '~/types.ts'
import { cn } from '~/utils/index.ts'
import { Image } from '~/components/Image.tsx'
import { Typography } from '~/components/Typography.tsx'

type Props = Album

export function PortfolioCart({
	title,
	slug,
	heroImage,
	videosCollection,
	photosCollection,
}: Props) {
	const reducedMotion = useReducedMotion()
	return (
		<motion.li
			initial={{ opacity: 0, y: 160 }}
			whileInView={{ opacity: 1, y: 0 }}
			viewport={{ once: true }}
			transition={{
				duration: reducedMotion ? 0 : 0.3,
			}}
			className={cn(
				'relative isolate h-80 w-64 overflow-hidden',
				'before:absolute before:left-0 before:top-0 before:z-10 before:block before:aspect-square before:border-b-[3rem] before:border-l-[3rem] before:border-b-transparent before:border-l-secondary before:drop-shadow-lg',
				'after:absolute after:bottom-0 after:right-0 after:z-10 after:block after:aspect-square after:border-r-[3rem] after:border-t-[3rem] after:border-r-secondary after:border-t-transparent after:drop-shadow-lg',
			)}
		>
			<Link to={`/portfolio/${slug}`} className="group">
				<Image
					webp={`${heroImage.url}?h=480&fm=webp`}
					png={`${heroImage.url}?h=480&fm=png`}
					className="absolute h-full w-full scale-95 object-cover transition duration-500 ease-out group-hover:scale-110"
					loading="lazy"
					width={256}
					height={320}
				/>
				<div className="absolute bottom-6 left-0 flex h-1/4 w-[94%] flex-col justify-center bg-keyDarker px-2 shadow-md drop-shadow-lg">
					<Typography
						as="h3"
						color="light"
						className="overflow-hidden truncate"
					>
						{title}
					</Typography>
					<Typography color="light">
						{videosCollection.total + photosCollection.total} Elementów
					</Typography>
				</div>
			</Link>
		</motion.li>
	)
}
