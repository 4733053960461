import * as React from 'react'
import { useFetcher } from '@remix-run/react'
import {
	getFormProps,
	getInputProps,
	getTextareaProps,
	useForm,
} from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { useSpinDelay } from 'spin-delay'

import { contactSchema } from '~/utils/validators/contact-form.ts'
import { Button } from '~/components/Button.tsx'
import { Input } from '~/components/Input.tsx'
import { Spinner } from '~/components/Spinner.tsx'
import { type ActionData } from '~/routes/api+/contactForm.tsx'

import { ErrorList } from './Form.tsx'

export function ContactForm() {
	const contactFetcher = useFetcher<ActionData>()
	const showSpinner = useSpinDelay(contactFetcher.state !== 'idle', {
		delay: 200,
		minDuration: 300,
	})
	const [form, fields] = useForm({
		id: 'booking',
		lastResult: contactFetcher.data?.result,
		constraint: getZodConstraint(contactSchema),
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: contactSchema })
		},
		shouldValidate: 'onBlur',
	})

	return (
		<contactFetcher.Form
			method="post"
			action="/api/contactForm"
			className="outline-box-rounded-2xl mx-auto flex w-3/4 flex-col items-end gap-2 rounded-2xl bg-primary p-4 text-primary lg:p-12"
			{...getFormProps(form)}
		>
			<AuthenticityTokenInput />
			<HoneypotInputs />
			<Input
				label="Imię Nazwisko"
				placeholder="Imię Nazwisko"
				required
				{...getInputProps(fields.name, { type: 'text' })}
			/>
			<ErrorList id={fields.name.errorId} errors={fields.name.errors} />

			<Input
				label="Email"
				placeholder="Email"
				required
				{...getInputProps(fields.email, { type: 'email' })}
			/>
			<ErrorList id={fields.email.errorId} errors={fields.email.errors} />

			<Input
				label="Telefon"
				placeholder="Telefon"
				required
				{...getInputProps(fields.phone, { type: 'text' })}
			/>
			<ErrorList id={fields.phone.errorId} errors={fields.phone.errors} />

			<Input
				as="textarea"
				label="Treść"
				placeholder="Treść"
				rows="6"
				required
				{...getTextareaProps(fields.content)}
			/>
			<ErrorList id={fields.content.errorId} errors={fields.content.errors} />

			<Button type="submit" disabled={contactFetcher.state !== 'idle'}>
				{showSpinner ? <Spinner className="h-7 w-7" /> : 'Wyślij'}
			</Button>

			<ErrorList id={form.errorId} errors={form.errors} />
			{contactFetcher.data?.result &&
			contactFetcher.data.result?.initialValue === null ? (
				<span className="self-start font-semibold text-lime-600">
					Twoja wiadomość została wysłana
				</span>
			) : null}
		</contactFetcher.Form>
	)
}
