import { motion, useReducedMotion } from 'framer-motion'

import { Image } from '~/components/Image.tsx'
import { Typography } from '~/components/Typography.tsx'

export function About() {
	const reducedMotion = useReducedMotion()
	return (
		<section id="about" className="bg-secondary py-52">
			<div className="mx-auto flex max-w-screen-lg flex-wrap px-12 md:flex-nowrap">
				<motion.div
					initial={{ opacity: 0.75, scale: 0.75 }}
					whileInView={{ opacity: 1, scale: 1 }}
					viewport={{ once: true }}
					transition={{
						duration: reducedMotion ? 0 : 0.5,
					}}
					className="mb-6 mr-6 shrink-0"
				>
					<Image
						webp="/images/about.webp"
						png="/images/about.png"
						className="aspect-square h-44 w-auto rounded-full object-cover shadow-lg shadow-black md:h-64"
						loading="lazy"
						alt="Krzysztof Dąbrowski"
						width={400}
						height={400}
					/>
				</motion.div>
				<div className="overflow-hidden">
					<motion.div
						initial={{ opacity: 0.75, x: -100 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{
							duration: reducedMotion ? 0 : 0.3,
						}}
					>
						<Typography as="h2" color="light">
							O mnie
						</Typography>
					</motion.div>
					<motion.div
						initial={{ opacity: 0.75, x: -100 }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
						transition={{
							duration: reducedMotion ? 0 : 0.3,
						}}
					>
						<Typography color="light" className="break-words">
							Zapraszam do magicznego świata fotografii i filmu w Waszym wielkim
							dniu! Oferuję pełen zakres usług fotograficznych i wideo dla
							wyjątkowych chwil w Waszym życiu: ślubów, komunii, chrztów,
							prymicji i więcej. Moją pasją jest sztuka fotografii i filmowania
							ślubów. Dzięki mojemu wieloletniemu doświadczeniu oraz
							profesjonalnemu sprzętowi tworzę unikalne, niepowtarzalne
							reportaże ślubne w formie zdjęć i filmów. Mam także specjalne
							zezwolenie na nagrywanie w miejscach sakralnych, zapewniając pełen
							spokój. Każdą ofertę dostosowuję do Waszych indywidualnych potrzeb
							i marzeń. Pracuję z pełnym szacunkiem i dyskrecją, bez żadnej
							presji. Moje reportaże ślubne to prawdziwe dzieła sztuki, które
							zachwycą Was zarówno kreatywnością, jak i dostępnymi cenami.
							Jesteście gotowi na niezapomniane wspomnienia? Znajdźmy wspólnie
							magię w Waszych chwilach szczęścia!
						</Typography>
					</motion.div>
				</div>
			</div>
		</section>
	)
}
