import { motion, useReducedMotion } from 'framer-motion'

import { type Album } from '~/types.ts'
import { PortfolioCart } from '~/components/PortfolioCart.tsx'
import { Typography } from '~/components/Typography.tsx'

type Props = {
	albums: Album[]
}

export function Portfolio({ albums }: Props) {
	const reducedMotion = useReducedMotion()
	return (
		<section
			id="portfolio"
			className="flex w-full flex-col items-center gap-12 px-2 py-24"
		>
			<motion.div
				initial={{ opacity: 0.75, y: 50 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				transition={{
					duration: reducedMotion ? 0 : 0.3,
				}}
			>
				<Typography color="key" as="h2">
					Portfolio
				</Typography>
			</motion.div>
			<ul className="flex flex-wrap items-center justify-center gap-8">
				{albums.map(el => (
					<PortfolioCart key={el.slug} {...el} />
				))}
			</ul>
		</section>
	)
}
