import { z } from 'zod'

const defaultErrors = {
	required_error: 'Pole wymagane',
	invalid_type_error: 'Błędna wartość',
}

export const contactSchema = z.object({
	name: z
		.string(defaultErrors)
		.min(5, 'Wartość jest za krótka')
		.max(128, 'Wartość jest za długa'),
	email: z.string(defaultErrors).email('Błędny email'),
	phone: z
		.string(defaultErrors)
		.regex(
			/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
			'Błędny number telefonu',
		),
	content: z
		.string(defaultErrors)
		.min(8, 'Treść jest za krótka')
		.max(1280, 'Treść jest za długa'),
})
